// 农人圈相关接口
import { http } from "../src/utils/index";

export const getSpaceListApi = (data: any) => {
    return http({
        url: `/cooperationH5/farmer/circle/friend/circle/pageList?page=${data.page}&pageSize=${data.pageSize}`,
        method: 'GET'
    })
}

export const updateZanApi = (recordId: string) => {
    return http({
        url: `/cooperationH5/farmer/circle/friend/circle/zan?recordId=${recordId}`,
        method: 'GET'
    })
}

// 回复
export const replyChatApi = (data: object) => {
    return http({
        url: `/cooperationH5/farmer/circle/friend/circle/comment`,
        method: 'POST',
        data
    })
}

// 获取未读消息
export const getUnreadApi = () => {
    return http({
        url: '/cooperationH5/user/message/countNotRead',
        method: 'GET'
    })
}

// 农户朋友圈详情
export const farmerChatDetailApi = (circleId: string) => {
    return http({
        url: '/cooperationH5/farmer/circle/friend/circle/info?circleId=' + circleId,
        method: 'GET'
    })
}

// 消息列表
export const getMessageListApi = (data: { page: number, pageSize: number }) => {
    return http({
        url: '/cooperationH5/user/message/pageList?page=' + data.page + '&pageSize=' + data.pageSize,
        method: 'GET'
    })
}

// 将消息标记已读
export const readMessageListApi = () => {
    return http({
        url:'/cooperationH5/user/message/delNotRead',
        method:'GET'
    })
}
// 删除评论
export const delCommentApi = (id:string) => {
    return http({
        url:'/cooperationH5/farmer/circle/delComment?commentId='+id,
        method:'GET'
    })
}
// 获取游客模式平台信息
export const employeeIdApi = (id:string) => {
    return http({
        url:'/cooperationH5/farmer/circle/employee/tourist?employeeId='+id,
        method:'GET'
    })
}
// 获取游客模式农户信息
export const tourFarmerApi = (id:string,lat:string,lng:string) => {
    return http({
        url:`/cooperationH5/farmer/circle/farmer/tourist?farmerId=${id}&lat=${lat}&lng=${lng}`,
        method:'GET'
    })
}
// 获取游客模式资源信息
export const touristApi = (id:string,lat:string,lng:string) => {
    return http({
        url:`/cooperationH5/farmer/circle/source/tourist?sourceId=${id}&lat=${lat}&lng=${lng}`,
        method:'GET'
    })
}
// 获取游客模式合作社信息
export const tourCooperationApi = (id:string) => {
    return http({
        url:`/cooperationH5/farmer/circle/cooperation/tourist?cooperationUserId=${id}`,
        method:'GET'
    })
}