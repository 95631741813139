const qiniu = require('qiniu-js');

// 七牛云需要的信息
interface OptionType {
    key: string, // 资源名
    token: string // token 该token从后端获取，非登录时的token
}

// 七牛云上传时的配置
interface ConfigType {
    useCdnDomain?: boolean,
    disableStatisticsReport?: boolean,
    region?: any,
    retryCount?: number,
    concurrentRequestLimit?: number,
    checkByMD5?: boolean,
}

// 七牛云extra配置
interface ExtraType {
    fname: string,
    mimeType: string,
    customVars: object,
    metadata: object,
}

export default class UploadFile {
    // 视频和图片格式，如果有新的格式，请在这里继续添加
    private TYPE: RegExp = /(\.jpeg||\.png||\.jpg||\.gif||\.mp4||\.avi||\.iso||\.wmv||\.flv||\.WMV||\.AVI||\.MOV||\.MKV||\.M4V||\.VOB||\.rmvb||\.rm||\.f4v||\.mv)+$/;
    constructor() {

    }

    public upload(file: any, options: OptionType, extra: ExtraType, config: ConfigType,successFn:Function,errFn:Function,finalFn:Function): void {
        // 把文件后缀和后端返回的命名进行拼接
        const suffix = file.name.match(this.TYPE)
        let tempPath = ""
        if (suffix && suffix.length > 0) {
            tempPath = options.key + suffix[0]
        }
        const observer = qiniu.upload(file, tempPath || options.key + ".png", options.token, extra, config);
        observer.subscribe({
            next(res: any) {
                successFn&& successFn(res)
            },
            error(err: any) {
                errFn && errFn(err)
            },
            complete(res: any) {
                finalFn && finalFn(res)
            }
        })
    }

}