
import { getUnreadApi } from "../../../api/farmerSpace";
import { defineComponent, onMounted, reactive, ref, toRefs } from "vue";
import { useRoute } from "vue-router";
import mitt from "@/utils/mitt";
import store from "@/store";
// 目前底部tab菜单所对应的路由
const tabbarList = ["/", "/farmerSpace", "/purchaseRecord", "/user"];
interface StateType {
  unreadData: { count: number };
}
export default defineComponent({
  setup() {
    let active = ref(0);
    const state = reactive<StateType>({
      unreadData: { count: 0 },
    });
    const route = useRoute();
    async function getUnreadData() {
      const result: any = await getUnreadApi();
      if (result.data.code === 0 && result.data.data) {
        store.commit('setNotRead', result.data.data)
      } else {
        store.commit('setNotRead', {count:0,lastHeadImg:''})
      }
    }
    getUnreadData();
    // 监听更新tabbar
    // mitt.on("onTabbarCount", () => {
    //   getUnreadData();
    // });
    onMounted(() => {
      // 如果选择了非第0个tab的情况下刷新页面，会发现tab默认回到了第一个，但页面没有
      // 这里就是找到目前路由对应的下标，避免这个问题
      const index = tabbarList.indexOf(route.path);
      active.value = index == -1 ? 0 : index;
    });
    return {
      ...toRefs(state),
      ...toRefs(store.state),
      active,
    };
  },
});
