
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vant from 'vant'
import 'vant/lib/index.css'
import '@/assets/commonStyle.scss'
import VConsole from 'vconsole'

import StcpComponent from "stcp-h5-component"
import "stcp-h5-component/dist/stcp-h5-component.css"

import { getVideoPoster } from "@/utils/util"

if (process.env.NODE_ENV != 'production') {
    const vconsole = new VConsole()
}

const app = createApp(App);
app.use(store);
app.use(router);
app.use(vant);
app.use(StcpComponent)
app.mount('#app');

app.config.globalProperties.getVideoPoster = getVideoPoster