import axios, { Method } from 'axios';
// 如果是开发环境就直接访问本地的地址，方便本地跨域
export const BASE_URL = process.env.NODE_ENV == 'development' ? process.env.VUE_APP_TARGET_URL : process.env.VUE_APP_BASE_URL
import { Notify } from 'vant';
import { h5AutoLogin } from '../utils/util'
import { getConfigApi } from "../../api/user";
import wx from "weixin-js-sdk";
interface axiosType {
    method?: Method,
    url: string,
    data?: any,
    params?: any,
    header?: any,
    timeout?: number,
    expel?: boolean // 用于判断是否将信息抛给页面
}
const instance = axios.create({
    baseURL: BASE_URL
    // timeout: 10000,
})
instance.interceptors.request.use((config) => {
    config.headers.token = localStorage.getItem('token') || ''
    config.headers['Content-Type'] = 'application/json;charset=utf-8'
    config.headers.appid = process.env.VUE_APP_APPLICATION_ID
		config.headers.clientId = 4
		config.headers.platFrom = 5
    const tempData = localStorage.getItem('cooperationData') == 'undefined' || !localStorage.getItem('cooperationData') ? '{}' : localStorage.getItem('cooperationData') as string
    if (Object.keys(JSON.parse(tempData)).length > 0) {
        config.headers.cooperationId = JSON.parse(tempData).id
    } else {
        config.headers.cooperationId = ''
    }
    return config
})

export const http = (data: axiosType) => {
    return new Promise((resolve, reject) => {
        instance.request(data).then((res: IDataType) => {
					console.log(res);
					
            if(res.data.code == 107){
                location.href = process.env.VUE_APP_TARGET_URL + '/#/showQR'
            }else if (res.data.code == 0) {
                resolve(res)
            } else if (res.data.code == 401) {
                // 登录过期
                localStorage.removeItem('token')
                // h5AutoLogin()
            } else if (res.data.code == 110) {
                // 未绑定手机号 先去绑定
                if (res.data.data && res.data.data.token) {
                    localStorage.setItem('token', res.data.data.token)
                }
                location.replace('/#/login');
            } else if (res.data.code == 102) {
                // 用户未登录
                localStorage.removeItem('token')
                // h5AutoLogin()
            } else if (res.data.code == 2) {
                if (data.expel) {
                    resolve(res)
                } else {
                    Notify(res.data.msg)
                }
            } else {

                if (data.expel) {
                    resolve(res)
                } else {
                    Notify(res.data.msg)
                }
            }
        }).catch((err: any) => {
            reject(err)
        })
    })

}

/**
 * 
 * @param configList :需要授权的列表，例：["getLocation"]
 */
export const getConfigWeixin = async (configList:Array<string>)=>{
    
      // 获取微信授权通过公众号自带的定位获取经纬度
      const res: any = await getConfigApi(process.env.VUE_APP_APPLICATION_ID);
      wx.config({
        debug: false, // 调试模式是否开启
        appId: process.env.VUE_APP_APPLICATION_ID, // 必填，公众号的唯一标识
        timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.data.signature, // 必填，签名，见附录1
        jsApiList: configList, // 获取地理位置接口
      });
    
}
