import { h5Login } from '../../api/user'
import { ImagePreview } from 'vant'
import UploadFile from "@/utils/uploadFile";
import router from '@/router';

export const MAP_KEY = 'OGSBZ-LFLWV-6RDPF-UFRUX-UQP3J-OGBMG'
export const MAP_NAME = 'xfarmer'
export const QI_LIU_YUN_APPID = '74HLOJZhLwJNnJg-UZ2ImtoiI7BLsKKHhc5j-SjH' // 七流云APPID

export const QI_LIU_BASE_URL = 'https://qiniu.xfarmer.com/'

// 获取七牛云视频封面图
export const getVideoPoster = () => {
  return `?vframe/jpg/offset/0.1`
}
// 获取url上面的参数
export const getQueryString = (name: string) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return unescape(r[2]); return null;
}

export const h5AutoLogin = () => {
  const code = getQueryString('code');
  let param = location.href.split('#/') && location.href.split('#/')[1]
  if (!localStorage.getItem('token') && !code) {
    location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${process.env.VUE_APP_APPLICATION_ID}&redirect_uri=${encodeURIComponent(process.env.VUE_APP_TARGET_URL + '/#/' + param)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
  } else if (!localStorage.getItem('token') && code) {
    h5Login(code).then((res: any) => {
      if (res.data.code == 0) {
        localStorage.setItem('token', res.data.data.token)
        location.href = process.env.VUE_APP_TARGET_URL + '/#/' + param
      }
    }).catch(err => {
      console.log(err, 'rrrrr')
    })
  }
}

// 时间转换
export const dateFormat = (e: string | number = new Date().getTime()) => {
  const date = new Date(e)
  const y = date.getFullYear()
  const m = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
  const h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
  const mint = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
  const mill = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
  return {
    YYYY: y,
    MM: m,
    DD: day,
    hh: h,
    mm: mint,
    ss: mill
  }

}

// 去除七牛云的url后面的参数 用于原图展示
export const previewImage = (e: any | any[], pos: number = 0) => {
  if (!e) {
    return;
  }
  const reg = /(\?imageView2\/\d+\/\w+\/\w*\/\w+\/\w*\/\w+\/\w+)/g;
  if (Array.isArray(e)) {
    e.forEach((item) => {
      item.replace(reg, "");
    });
    ImagePreview({
      images: e,
      startPosition: pos,
    });
  } else {
    const url = e.replace(reg, "");
    ImagePreview([url]);
  }
};

export const getScreenInfo = () => {
  return { screenWidth: document.body.clientWidth, screenHeight: document.body.clientHeight }
}

type key = "fileName" | "token"
type result = {
  data: Record<key, string>
}

export type itemType = {
  file: File,
  id?: number | string,
  poster?: string,
  progress?: number,
  time?: number,
  url?: string,
  coverImg?: string,
  status?: number // 0 上传中   1 上传完成
}

type fileType = {
  userType: string,
  fileType: string
}

const upload = new UploadFile()
/**
 * 
 * @param item 具体的文件对象
 * @param qiniuParam 通过接口获取的七牛云参数
 * @param fileType fileType: 0 图片 1 视频 2 其他文件.  userType 1小程序, 2 h5, 3 saas后台, 4 企业微信小程序，5 公司的h5， 6 公司的后台系统  
 */
export const directUploadFile = (item: itemType, qiniuParam: result, fileType: fileType = { userType: '2', fileType: '0' },userToken="") => {
  upload.upload(
    item.file,
    { key: qiniuParam.data.fileName, token: qiniuParam.data.token },
    {
      fname: qiniuParam.data.fileName,
      customVars: {
        "x:userToken":userToken??localStorage.getItem("token"),
        "x:userType": fileType.userType,
        "x:fileType": fileType.fileType,
      },
      metadata: {},
      mimeType: "",
    },
    {},
    (response: any) => {
      item.progress = Math.floor(response.total.percent);
    },
    (err: any) => {
      console.log(err, "errrr");
    },
    (result: any) => {
      // 上传成功
      if (result.code === 0) {
        item.id = result.data.id;
        // type 0 图片 1视频 2其他文件
        if (result.data.type === 0) {
          item.poster = result.data.url.indexOf(QI_LIU_BASE_URL) === -1 ? QI_LIU_BASE_URL + result.data.url : result.data.url;
          item.status = 1
          item.url = result.data.url
        } else if (result.data.type === 1) {
          item.poster = result.data.url.indexOf(QI_LIU_BASE_URL) === -1 ? QI_LIU_BASE_URL + result.data.coverImg : result.data.coverImg
          item.status = 1
          item.time = result.data.time
          item.url = result.data.url
        } else if (result.data.type === 2) {
          // 暂无其他
        }
      }
    }
  );
}

export const getDuration = (v: string) => {
  if (!v) {
    return "00:00";
  }

  let h = Math.floor(parseInt(v) / 1000 / 60).toString();
  let m = Math.floor((parseInt(v) / 1000) % 60).toString();
  h = h.padStart(2, "0");
  m = m.padStart(2, "0");
  return h + ":" + m;
};

// 根据时间区间获取对应数据 0:自定义区间, 1: 今天,2:昨天，3:本月,4:本年
export function getAreaDate(n: number = 0): string {
  let s1 = '';
  let day1 = new Date();
  let year = day1.getFullYear().toString().padStart(2, '0');
  let month = (day1.getMonth() + 1).toString().padStart(2, '0');
  switch (n) {
    case 0:
      break
    case 1:
      day1.setTime(day1.getTime());
      s1 = year + "-" + month + "-" + (day1.getDate().toString().padStart(2, '0'));
      s1 = s1 + '~' + s1;
      break;
    case 2:
      day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000);
      s1 = day1.getFullYear().toString() + "-" + (day1.getMonth() + 1).toString().padStart(2, '0') + "-" + (day1.getDate().toString().padStart(2, '0'));
      s1 = s1 + '~' + s1;
      break;
    case 3:
      let lastDay = new Date(Number(year), Number(month), 0).getDate();
      s1 = `${year}-${month}-01~${year}-${month}-${lastDay.toString().padStart(2, '0')}`
      break;
    case 4:
      s1 = `${year}-01-01~${year}-12-31`
      break
  }
  return s1
}

// 金额格式化
export const numberFormat = (number: any, decimals: number, dec_point: string = '.') => {
  /*
  * 参数说明：
  * number：要格式化的数字
  * decimals：保留几位小数
  * dec_point：小数点符号
  * thousands_sep：千分位符号
  * roundtag:舍入参数，默认 "ceil" 向上取,"floor"向下取,"round" 四舍五入
  * */
  number = (number + '').replace(/[^0-9+-Ee.]/g, '')
  var n = !isFinite(+number) ? 0 : +number
  var prec = !isFinite(+decimals) ? 2 : Math.abs(decimals)
  var dec = (typeof dec_point === 'undefined') ? '.' : dec_point
  var toFixedFix = function (n: any, prec: any) {
    var k = Math.pow(10, prec)
    return '' + parseFloat(Math.round(parseFloat((n * k).toFixed(prec * 2))).toFixed(prec * 2)) / k
  }
  let s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(dec)
}

// 时间转化成00：00格式
export const convertSec2Str = (total: number) => {
  if (!total) {
    return '00:00'
  }
  total = total / 1000
  total = Number(total.toFixed(0))
  let min = repairZero(Math.floor(total / 60));
  let sec = repairZero(Math.floor(total % 60));
  return `${min}:${sec}`
}
// 分秒补0
export const repairZero = (num: any) => {
  num = num.toString();
  return num[1] ? num : '0' + num
}


// 将时间转化为“几分钟前，几小时前，具体日期”
export const initCreateFn = (value: any) => {
  value = value.replace(/-/g, '/')
  // 拿到当前时间戳和发布时的时间戳
  var curTime = new Date()
  //计算差值
  var timeDiff = curTime.getTime() - toTimeStamp(value)
  // 单位换算
  var min = 60 * 1000
  var hour = min * 60
  var day = hour * 24
  // 计算发布时间距离当前时间的 天、时、分
  var exceedDay = Math.floor(timeDiff / day)
  var exceedHour = Math.floor(timeDiff / hour)
  var exceedMin = Math.floor(timeDiff / min)
  // 最后判断时间差
  if (exceedDay > 0) {
    return value.replace(/\//g, '.')
  } else {
    if (exceedHour < 24 && exceedHour > 0) {
      return exceedHour + '小时前'
    } else if (exceedMin < 60 && exceedMin > 0) {
      return exceedMin + '分钟前'
    } else {
      return '刚刚'
    }
  }
}

// 时间转换为时间戳
export const toTimeStamp = (time: any) => {
  if (!time) return 0
  if (typeof time === 'string') {
    return new Date(time.replace(/-/g, '/')).getTime()
  } else {
    if (time.toString().length === 10) return new Date(time * 1000).getTime()
    else return new Date(time).getTime()
  }
}

// 十种合作社颜色，根据结尾的数字来匹配对应的颜色,比如合作社id是10，那就匹配下标为0的颜色。
export const cooperationColorList = ['#6FBAFF', '#46D1AF', '#6F91FF', '#FF8161', '#FF9A9A', '#FFA561', '#B183D5', '#A7BE69', '#69D9A1', '#D165B0'];
// 根据下标获取颜色
export const getColorful = (id: number) => {
  let str = id.toString()
  let result = str.substr(str.length - 1)
  return cooperationColorList[Number(result)];
}

// 四舍五入
export const toFixed = function (num: number|string, decimalPlaces: number = 2): number {
	num  = Number(num)
	const factor = Math.pow(10, decimalPlaces);
	return Math.round(num * factor) / factor;


}