import { http } from "../src/utils/index"
export const userLogin = (data: any) => {
    return http({
        url: '/cooperationH5/login/sendMsg',
        method: 'GET',
        params: data
    })
}

// 微信公众号授权
// h5登录
export const h5Login = (data: string) => {
    return http({
        url: `/cooperationH5/login/index?code=${data}`,
        params: { source: 1 }
    })
}

// 绑定手机号
export const bindPhoneNumber = (data: object) => {
    return http({
        url: '/cooperationH5/login/bind',
        method: 'POST',
        data
    })
}

// 获取我的信息
export const getMyData = () => {
    return http({
        url: '/cooperationH5/center/index',
        method: 'GET'

    })
}

// 上传图片
export const uploadImg = (data: any) => {
    return http({
        url: '/qiniu/uploadImg',
        method: 'POST',
        data
    })
}

// 上传视频
export const uploadVideo = (d:any) => {
    return http({
        url: '/qiniu/uploadBatchVideo',
        method:'POST',
        data:d,
        timeout: 1000 * 60 * 60 * 10
    })
}


// 获取省市区   
export const areaList = () => {
    return http({
        url: '/area/findAllList',
        method:'GET',
    })
}
// 获取下面县
export const AreaByCityCodeList = (params:object) => {
    return http({
        url: '/area/findStreetList',
        method:'GET',
        params
    })
}

// 获取下面县
export const getfindVillageList = (params:object) => {
    return http({
        url: '/area/findVillageList',
        method:'GET',
        params
    })
}

// 获取文件上传凭证，后端从七牛云拿的
export const getFileToken = (data:object) => {
    return http({
        url:'qiniu/uploadSinge',
        method:'POST',
        data
    })
}

// 获取微信jdk授权
export const getConfigApi = (id:any) => {
    return http({
        url:'/cooperationH5/weixin/findConfig?appid='+id,
        method:'get',
    })
}

// 经纬度逆解析
export const getLocationApi = (lat:number,lng:number) => {
    return http({
        url:`/area/parseCoordinate?lat=${lat}&lng=${lng}`,
        method:'get',
    })
}
// 保存打开H5页面的时间
export const postJumpMiniProgramApi1 = (openUrlTime:string,fromParam:string)=>{
	return http({
		url:`/user/click/saveOpenH5Time?openUrlTime=${openUrlTime}&fromParam=${fromParam}`,
		method:"get"
	})
}
// 获取微信小程序短链
export const postJumpMiniProgramApi2 = (data:any)=>{
	return http({
		url:`/user/click/find/wxUrl?id=${data.id}&clickUrlTime=${data.clickUrlTime}&appId=${data.appId}&path=${data.path}`,
		method:"get"
	})
}