import { createStore } from 'vuex'

export default createStore({
  state: {
    lngLat:{
      lng:'',
      lat:''
    },
    notReadObj:{
      count:0,
      lastHeadImg:''
    }
  },
  mutations: {
    setLngLat(state,inComing){
      state.lngLat = {
        lng:inComing.lng,
        lat:inComing.lat
        
      }
    },
    setNotRead(state,notReadObj){
      state.notReadObj = notReadObj
    }
  },
  actions: {
  },
  modules: {
  }
})
