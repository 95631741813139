import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Tabbar = _resolveComponent("Tabbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        (_openBlock(), _createBlock(_KeepAlive, null, [
          (_ctx.route.meta.keepAlive)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                key: _ctx.route.name
              }))
            : _createCommentVNode("", true)
        ], 1024)),
        (!_ctx.route.meta.keepAlive)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
              key: _ctx.route.name
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_openBlock(), _createBlock(_KeepAlive, null, [
      (_ctx.route.meta.showTab)
        ? (_openBlock(), _createBlock(_component_Tabbar, { key: 0 }))
        : _createCommentVNode("", true)
    ], 1024))
  ], 64))
}