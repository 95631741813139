import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/Home.vue'),
    meta: {
      showTab: true, // 是否显示到
      title: '首页',
      keepAlive:true
    }
  },
  {
    path: '/tempPage',
    name: 'TempPage',
    component: () => import(/* webpackChunkName: "tempPage" */ '@/RedirectPage.vue'),
    meta: {
      showTab: false,
      title: '资源操作'
    }
  },
  {
    path: '/addResource',
    name: 'AddResource',
    component: () => import(/* webpackChunkName: "addResource" */ '../views/home/addResource/addResource.vue'),
    meta: {
      showTab: false,
      title: '资源操作'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue'),
    meta: {
      showTab: false,
      title: '绑定手机号'
    }
  },
  {
    path: '/user',
    name: 'User',
    component: () => import(/* webpackChunkName: "user" */ '../views/user/myPage/myPage.vue'),
    meta: {
      showTab: true,
      title: '我的'
    }
  },
  {
    path: '/myInfo',
    name: 'myInfo',
    component: () => import(/* webpackChunkName: "user" */ '../views/user/myInfo/myInfo.vue'),
    meta: {
      showTab: false,
      title: '我的信息'
    }
  },
  {
    path: '/farmerManage',
    name: 'FarmerManage',
    component: () => import(/* webpackChunkName: "farmerManage" */ '../views/farmerManage/farmerManage.vue'),
    meta: {
      showTab: true,
      title: '农户管理'
    }
  },
  {
    path: '/farmingDetail/:id',
    name: 'FarmingDetail',
    meta: {
      showTab: false,
      title: '农事详情'
    },
    component: () => import(/* webpackChunkName: "farmingDetail" */ '../views/home/farmingDetail/farmingDetail.vue')
  },
  {
    path: '/farmingRecord',
    name: 'FarmingRecord',
    meta: {
      showTab: false,
      title: '农事记录'
    },
    component: () => import(/* webpackChunkName: "farmingRecord" */ '../views/home/farmingRecord/farmingRecord.vue')
  },
  {
    path: '/baseManage/:sourceId',
    name: 'BaseManage',
    meta: {
      showTab: false,
      title: '基地管理'
    },
    component: () => import(/* webpackChunkName: "baseManage" */ '../views/baseManage/baseManage.vue')
  },
  {
    path: '/baseManage/operationBase/:sourceId',
    name: 'OperationBase',
    meta: {
      showTab: false,
      title: '操作记录'
    },
    component: () => import(/* webpackChunkName: "operationBase" */ '../views/baseManage/operationTrailRecord/operationTrail.vue')
  },
  {
    path: '/baseManage/operationLand',
    name: 'OperationLand',
    meta: {
      showTab: false,
      title: '操作地块',
      keepAlive: true
    },
    component: () => import(/* webpackChunkName: "operationLand" */ '../views/baseManage/operationLand/operationLand.vue')
  },
  {
    path: '/baseManage/selectModel',
    name: 'SelectModel',
    meta: {
      showTab: false,
      title: '选择模型'
    },
    component: () => import(/* webpackChunkName: "selectModel" */ '../views/baseManage/plantModelList/plantModelList.vue')
  },
  {
    path: '/showVideo',
    name: 'ShowVideo',
    meta: {
      showTab: false,
      title: '查看视频'
    },
    component: () => import(/* webpackChunkName: "showVideo" */ '../views/showVideo/showVideo.vue')
  },
  {
    path: '/showImage',
    name: 'ShowImage',
    meta: {
      showTab: false,
      title: '查看照片'
    },
    component: () => import(/* webpackChunkName: "showImage" */ '../views/showImage/showImage.vue')
  },
  {
    path: '/trace',
    name: 'Trace',
    meta: {
      showTab: false,
      title: '溯源信息'
    },
    
    component: () => import(/* webpackChunkName: "trace" */ '../views/trace/trace.vue')
  },
  {
    path: '/feedBack',
    name: 'feedBack',
    meta: {
      showTab: false,
      title: '产品问题反馈'
    },
    component: () => import(/* webpackChunkName: "feedBack" */ '../views/feedBack/feedBack.vue')
  },
  {
    path: '/feedBackOk',
    name: 'feedBackOk',
    meta: {
      showTab: false,
      title: '产品问题反馈'
    },
    component: () => import(/* webpackChunkName: "feedBackOk" */ '../views/feedBack/feedBackOk.vue')
  },
  {
    path: '/vr',
    name: 'vr',
    meta: {
      showTab: false,
      title: 'vr'
    },
    component: () => import(/* webpackChunkName: "vr" */ '../views/appletPage/vr.vue')
  },
  {
    path: '/verify',
    name: 'Verify',
    meta: {
      showTab: false,
      title: '验证真伪'
    },
    component: () => import(/* webpackChunkName: "verify" */ '../views/trace/verify/verify.vue')
  },
  {
    path: '/showQR',
    name: 'ShowQR',
    meta: {
      showTab: false,
      title: '二维码'
    },
    component: () => import(/* webpackChunkName: "showQR" */ '../views/trace/showQR/showQR.vue')
  },
  {
    path: '/showMap',
    name: 'ShowMap',
    meta: {
      showTab: false,
      title: '溯源地图'
    },
    component: () => import(/* webpackChunkName: "showMap" */ '../views/trace/showMap/ShowMap.vue')
  },
  {
    path: '/purchaseRecord',
    name: 'PurchaseRecord',
    meta: {
      showTab: true,
      title: '收购记录',
      keepAlive: true
    },
    component: () => import(/* webpackChunkName: "purchaseRecord" */ '../views/purchase/purchaseRecord/PurchaseRecord.vue')
  },
  {
    path: '/farmerSpace',
    name: 'FarmerSpace',
    meta: {
      showTab: true,
      title: '新农人圈',
    },
    component: () => import(/* webpackChunkName: "farmerSpace" */ '../views/farmerSpace/farmerSpace.vue')
  },
  {
    path: '/updateFarming',
    name: 'UpdateFarming',
    meta: {
      showTab: false,
      title: '产品信息'
    },
    component: () => import(/* webpackChunkName: "updateFarming" */ '../views/purchase/purchaseRecord/updateRecord.vue')
  },
  {
    path: '/infoDetail/:id',
    name: 'InfoDetail',
    meta: {
      showTab: false,
      title: '新农人圈'
    },
    component: () => import(/* webpackChunkName: "infoDetail" */ '../views/farmerSpace/infoDetail.vue')
  },
  {
    path: '/messageList',
    name: 'MessageList',
    meta: {
      showTab: false,
      title: '消息列表'
    },
    component: () => import(/* webpackChunkName: "messageList" */ '../views/farmerSpace/messageList.vue')
  },
  
  {
    path: '/userDetail/:id',
    name: 'userDetail',
    meta: {
      showTab: false,
      title: '用户详情'
    },
    component: () => import(/* webpackChunkName: "userDetail" */ '../views/pyqGoTo/userDetail.vue')
  },
  
  
  {
    path: '/farmerDetail/:id',
    name: 'farmerDetail',
    meta: {
      showTab: false,
      title: '农户详情'
    },
    component: () => import(/* webpackChunkName: "farmerDetail" */ '../views/pyqGoTo/farmerDetail.vue')
  },
  
  
  {
    path: '/resourceDetail/:id',
    name: 'resourceDetail',
    meta: {
      showTab: false,
      title: '资源详情'
    },
    component: () => import(/* webpackChunkName: "resourceDetail" */ '../views/pyqGoTo/resourceDetail.vue')
  },{
    path: '/resourceImgVideo',
    name: 'resourceImgVideo',
    meta: {
      showTab: false,
      title: '资源影像'
    },
    component: () => import(/* webpackChunkName: "resourceImgVideo" */ '../views/pyqGoTo/resourceImgVideo.vue')
  },{
    path: '/vip',
    name: 'vip',
    meta: {
      showTab: false,
      title: '极农会员'
    },
    component: () => import(/* webpackChunkName: "vip" */ '../views/user/vip/vip.vue')
  },{
    path: '/downFilePdf',
    name: 'downFilePdf',
    meta: {
      showTab: false,
      title: '极农订单处理工作台'
    },
    component: () => import(/* webpackChunkName: "vip" */ '../views/appletPage/downFilePdf.vue')
  },{
    path: '/agent/deliverOrder',
    name: 'deliverOrder',
    meta: {
      showTab: false,
      title: '代理收货'
    },
    component: () => import(/* webpackChunkName: "deliverOrder" */ '../views/agent/deliverOrder.vue')
  },{
    path: '/agent/checkOrder',
    name: 'checkOrder',
    meta: {
      showTab: false,
      title: '代理验收'
    },
    component: () => import(/* webpackChunkName: "checkOrder" */ '../views/agent/checkOrder.vue')
  },{
    path: '/agent/agentSuccess',
    name: 'agentSuccess',
    meta: {
      showTab: false,
      title: '提交成功'
    },
    component: () => import(/* webpackChunkName: "agentSuccess" */ '../views/agent/agentSuccess.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

// 路由拦截
router.beforeEach((to, from, next) => {
  document.title = to.meta.title as string
  next()
})

export default router
